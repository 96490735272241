<style lang="less" scoped>
  @import "../../../assets/css/variables";

  .customer-content {
    margin: 0 auto;
    width: 92%;
    .el-form-item {
      margin-bottom: 18px !important;
    }
  }

  .order-image-wrapper {
    overflow: hidden;
    position: relative;
    height: 120px;
    width: 120px;
    line-height: 120px;
    border: 1px solid #bfcbd9;
    border-radius: 4px;
    font-size: 100px;
    // line-height: 126px;
    text-align: center;
    color: #ccc;
    cursor: pointer;
    span {
      position: relative;
      bottom: 10px;
      display: inline-block;
      line-height: 16px;
      width: 100%;
      word-wrap: break-word;
    }
    .image-content {
      position: relative;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .image-close {
      position: absolute;
      right: 0;
      top: 0;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      background-color: #1c2b36;
      color: #fff;
      font-size: @font-size-base;
    }
    .update {
      position: absolute;
      transform: rotate(45deg);
      top: 1px;
      right: -30px;
      width: 80px;
      height: 20px;
      line-height: 20px;
      font-size: 2px !important;
      color: #f00;
      background-color: #eee;
    }
  }

  .image-notice {
    width: 200px;
    padding-left: 10px;
    overflow: hidden;
    span {
      line-height: 12px;
      font-size: 12px;
      color: #ccc;
    }
  }
</style>
<template>
  <div>
    <div class="customer-content">
      <el-form
        ref="pubNumberForm"
        :rules="rules"
        :model="pubNumberInfo"
        label-width="80px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="名称:" prop="name">
              <el-input
                v-model="pubNumberInfo.name"
                placeholder="请输入公众号名称"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- {{brandList}} -->
          <!-- <el-col :span="12">
            <el-form-item label="链接地址:" prop="linkUrl">
              <el-input v-model="pubNumberInfo.linkUrl" placeholder="链接地址"></el-input>
            </el-form-item>
          </el-col>-->
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="品牌商:" prop="customer">
              <el-select
                style="width:100%;"
                v-model="pubNumberInfo.name"
                filterable
                remote
                clearable
                :disabled="isBrand || isEdit"
                :remote-method="queryCustomers"
                placeholder="选择品牌商"
              >
                <el-option
                  v-for="item in brandList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人:" prop="contact">
              <el-input v-model="pubNumberInfo.contact"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="联系电话:" prop="afterPhone">
              <el-input
                v-model="pubNumberInfo.afterPhone"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="APP ID:" prop="appId">
              <el-input
                v-model="pubNumberInfo.appId"
                placeholder="请输入微信app id"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="原始ID:" prop="originId">
              <el-input
                v-model="pubNumberInfo.originId"
                placeholder="请输入原始id"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="app密钥:" prop="appSecret">
              <el-input
                v-model="pubNumberInfo.appSecret"
                placeholder="请输入密钥"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="品牌图片">
          <form
            id="headImgfileForm1"
            class="none"
            method="post"
            enctype="multipart/form-data"
          >
            <input
              type="file"
              id="brandImageFile"
              @change="selectedImageFile1"
              name="upfile"
              accept="image/*;capture=camera"
            />
          </form>
          <div
            @click="triggerSelectFile1"
            class="order-image-wrapper pull-left"
          >
            <span style="font-size:12px;text-align:center;">
              用于设备配网的操作说明,请上传指定尺寸的图片
              <br />
              300 × 300
            </span>
          </div>
          <div
            @click="delImage(index)"
            :class="[
              'order-image-wrapper',
              'pull-left',
              'm-b-md',
              { 'image-m-l': (index + 1) % 3 !== 0 },
            ]"
            style="margin-right:4px"
            v-for="(image, index) in imageList"
          >
            <div
              class="image-content "
              :style="{
                'background-image': 'url(' + API_ROOT + image.serverImage + ')',
              }"
              v-loading.body="loading"
            ></div>
            <div class="image-close" style="background-color: transparent">
              <i class="fa fa-times-circle" style="color:#f00;"></i>
            </div>
          </div>
          <!-- <div class='image-notice'>
            <span>此处上传的图片用于设备配网方面的操作说明的图片展示，
            请上传指定尺寸的图片</span>
          </div> -->
        </el-form-item>
        <el-form-item label="二维码:">
          <form
            id="headImgfileForm"
            class="none"
            method="post"
            enctype="multipart/form-data"
          >
            <input
              type="file"
              id="orderImageFile"
              @change="selectedImageFile"
              name="upfile"
              accept="image/*;capture=camera"
            />
          </form>
          <div class="image-content m-t-md clearfix" style="margin-top: -5px;">
            <div
              @click="triggerSelectFile"
              class="order-image-wrapper pull-left"
            >
              <span v-if="!pubNumberInfo.qrticket">+</span>
              <img
                v-if="pubNumberInfo.qrticket"
                width="80"
                height="80"
                :src="API_ROOT + pubNumberInfo.qrticket"
              />
              <span class="update" v-if="pubNumberInfo.qrticket">修改</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item class="text-right m-t-md">
          <el-button type="primary" size="large" @click="onSubmit">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
  import { searchPubNumberByKey } from "../../../services/customer";
  import {
    createPubNumber,
    queryBrander,
    getCustomerById,
    updatePubNumberInfo,
  } from "../../../services/customer";
  import { uploadImageFile } from "../../../services/common";
  import { API_ROOT } from "../../../config";
  import { mapGetters, mapActions } from "vuex";
  import { getFileUrl } from "../../../utils/tools/tools";
  import { Notification } from "element-ui";
  import { valiInput, valiSelect } from "../../../utils/validator/element";
  export default {
    data() {
      const valilinkUrlTel = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请输入联系人电话"));
        } else {
          if (
            !/(^(\d{3,4}-)?\d{7,8})+(\-[0-9]{1,4})?$|^(1[3|4|5|7|8]\d{9}$)/.test(
              value
            )
          ) {
            return callback(new Error("请输入正确的联系人电话"));
          } else {
            callback();
          }
        }
      };
      const valiAddr = (rule, value, callback) => {
        if (value) {
          if (!/^(http(s)?:\/\/)?(www\.)?[\w-]+\.\w{2,4}(\/)?$/.test(value)) {
            return callback(new Error("请输入正确的链接地址"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      };
      const valiAppsecret = (rule, value, callback) => {
        if (value) {
          if (value.toString().length < 6) {
            return callback(new Error("字符长度不低于6位"));
          } else {
            callback();
          }
        } else {
          callback(new Error("不能为空"));
        }
      };
      return {
        disabled: false,
        API_ROOT,
        pager: {
          size: 20,
          page: 1,
        },
        isBrand: false,
        loading: false, //加载框
        pubNumberInfo: {},
        brandList: [],
        imageList: [],
        imgUrlList: [],
        loading: false,
        rules: {
          name: [{ validator: valiInput, trigger: "blur" }],
          customer: [{ validator: valiSelect, trigger: "change" }],
          linkUrl: [{ validator: valiAddr, trigger: "blur" }],
          connectTel: [{ validator: valilinkUrlTel, trigger: "change" }],
          // isProxy: [
          //   {validator: valiSelect, trigger: 'change'}
          // ],
          appId: [{ validator: valiInput, trigger: "blur" }],
          originId: [{ validator: valiInput, trigger: "blur" }],
          appSecret: [{ validator: valiAppsecret, trigger: "blur" }],
        },
      };
    },
    components: {
      // 'other-component':OtherComponent,
    },
    computed: {
      ...mapGetters({ sorts: "sorts" }),
      ...mapGetters({ user: "userInfo" }),
    },
    props: {
      customerDetailVisible: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
      isEdit: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
      isRootLevel: {
        type: Boolean,
        default: function() {
          return true;
        },
      },
      proppubNumberInfo: {
        type: Object,
        default: function() {
          return {
            id: "",
            name: "",
            linkUrl: "",
            customer: "",
            connectName: "",
            connectTel: "",
            appId: "",
            appSecret: "",
            originId: "",
            qrticket: "",
            imageUrls: "",
          };
        },
      },
    },
    methods: {
      /**
       * 权限判断
       */
      checkPermission() {
        // let roleId = this.user.roleId;
        // if (roleId !== 1) {
        //   this.disabled = true;
        //   this.pubNumberInfo.proxyCustomer = this.brandList[0].id;
        // }
        const userRole = this.user.roleCode;
        if (!/Admin/.test(userRole)) {
          this.disabled = true;
          // if (this.brandList[0].id)

          this.pubNumberInfo.proxyCustomer = this.user.customerName;
          // console.log("2: "+this.brandList)
        }
      },

      /**
       * 获取所有的客户
       */
      async queryPubNumbers() {
        // console.log(1111111111111)
        // this.listLoading = true;
        // let searchParam = Object.assign({}, this.filters)
        const responseData = await searchPubNumberByKey();
        //const responseData = await searchPubNumberByKey();
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.pubNumberList = responseData.data.datas || [];
          // console.log("pubNumberList",this.pubNumberList)
          this.pubNumberInfo = this.pubNumberList[0];
          console.log("pubNumberInfo", this.pubNumberInfo);
          // this.total = responseData.data.totalCount;

          if (this.pubNumberInfo.imageUrls) {
            let temp = this.pubNumberInfo.imageUrls.split(",");
            temp.forEach((item) => {
              this.imageList.push({ fileImage: item, serverImage: item });
            });
          }
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /*  async queryCompanys() {
      this.listLoading = true;
      const responseData = await searchCompanysByKey("", this.pager);
      // const responseData = await searchCompanysByKey();
      if (responseData && responseData.errorCode === 0 && responseData.data) {
        this.companyInfo = responseData.data.content [0] || {};
        console.log(this.companyInfo)
        let temp = this.companyInfo.imageUrl.split(",");
        temp.forEach((item) => {
          this.imageList.push({ fileImage: item, serverImage: item });
        });
        // console.log("aaa");
      }
      this.loaded = true;
      this.listLoading = false;
    },
 */

      /**
       * 获取所有的品牌商
       */
      /*  async queryCustomers(key){
          const responseData = await queryBrander({isProxy: true}, this.pager);
          if (responseData.errorCode === 0) {
            this.brandList = responseData && responseData.data || [];
            // this.pubNumberInfo = this.brandList[0]
            
            let temp = this.brandList.imageUrl.split(",");
            temp.forEach((item) => {
            this.imageList.push({ fileImage: item, serverImage: item });
        }); 
          console.log('this.pubNumberInfo',this.pubNumberInfo)
          }
          this.loaded = true;
          this.listLoading = false;
      }, */

      /**
       * 跳转到编辑
       */

      /*  handleEdit: function (index, row) {
        this.customerDetailVisible = true;
        // row;
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedPubNumber = Object.assign({}, row);
        this.pubNumberInfo = {
          id: choosedPubNumber.id,
          name: choosedPubNumber.name,
          linkUrl: choosedPubNumber.linkUrl,
          customer: choosedPubNumber.customer,
          connectName: choosedPubNumber.connectName,
          connectTel: choosedPubNumber.connectTel,
          appId: choosedPubNumber.appId,
          appSecret: choosedPubNumber.appSecret,
          originId: choosedPubNumber.originId,
          qrticket: choosedPubNumber.qrticket,
          imageUrls: choosedPubNumber.imageUrls,
        }
      }, */
      /**
       * [更新客户信息]
       * @return {[type]} [description]
       */
      async updatePubNumberInfo() {
        let pubNumberInfoData = null;
        const info = Object.assign({}, this.pubNumberInfo);
        info.imageUrls = this.imgUrlList.join(",");
        // console.log(info);
        if (this.isEdit) {
          pubNumberInfoData = await updatePubNumberInfo(info);
        } else {
          delete info.id;
          pubNumberInfoData = await createPubNumber(info);
        }
        if (
          pubNumberInfoData &&
          pubNumberInfoData.errorCode === 0 &&
          pubNumberInfoData.data
        ) {
          this.$emit("onSubmit", pubNumberInfoData.data);
          Notification.success({
            title: "成功",
            message: this.isEdit ? "修改公众号成功" : "添加公众号成功",
          });
        } else {
          Notification.error({
            title: "失败",
            message: pubNumberInfoData.data.errorMessage,
          });
        }
        return false;
      },

      /**
       * 触发文件选择
       */
      triggerSelectFile() {
        document.querySelector("#orderImageFile").click();
      },
      triggerSelectFile1() {
        document.querySelector("#brandImageFile").click();
      },
      /**
       * 选中
       */
      selectedImageFile1($event) {
        let files = $event.target.files;
        let file = null;
        if (files && files.length) {
          file = files[0];
        }

        if (file && /^image\/\w+/.test(file.type)) {
          if (file.size / 1024 / 1024 / 10 > 1) {
            //10M
            Notification.error({
              title: "错误",
              message: "请选择一个小于10M图片",
            });
            return;
          }
        } else {
          Notification.error({
            title: "错误",
            message: "请选择一个图片文件",
          });
          return;
        }
        //获取 URL.createObjectURL 地址
        const fileImageUrl = getFileUrl("#brandImageFile");
        let image = new Image();
        image.src = fileImageUrl;
        this.loading = true;
        image.onload = () => {
          this.imageList.push({ fileImage: fileImageUrl, serverImage: "" });
          this.uploadImageFile(file, "two");
        };
        image.onerror = () => {
          Notification.error({
            title: "错误",
            message: "请选择一个正确的图片文件",
          });
        };
      },

      /**
       * 选中
       */
      selectedImageFile($event) {
        let files = $event.target.files;
        let file = null;
        if (files && files.length) {
          file = files[0];
        }

        if (file && /^image\/\w+/.test(file.type)) {
          if (file.size / 1024 / 1024 / 10 > 1) {
            //10M
            Notification.error({
              title: "错误",
              message: "请选择一个小于10M图片",
            });
            return;
          }
        } else {
          Notification.error({
            title: "错误",
            message: "请选择一个图片文件",
          });
          return;
        }
        //获取 URL.createObjectURL 地址
        this.loading = true;
        this.uploadImageFile(file, "one");
      },
      /**
       * @param index 在imageList中的下标
       */
      delImage(index) {
        this.imageList.splice(index, 1);
      },

      /**
       * 上传图片
       * @param imageFile Blob file
       */
      async uploadImageFile(imageFile, tag) {
        const aafile = new File([imageFile], "cnm.png"); //修改文件名
        const uploadData = await uploadImageFile(aafile);
        if (
          uploadData &&
          uploadData.errorCode === 0 &&
          uploadData.data &&
          uploadData.data.result
        ) {
          if (tag == "one") {
            this.pubNumberInfo.qrticket = uploadData.data.fpicurl;
          } else {
            this.imageList[this.imageList.length - 1].serverImage =
              uploadData.data.fpicurl;
          }
          this.loading = false;
          document.getElementById("headImgfileForm").reset();
          document.getElementById("headImgfileForm1").reset();
        }
      },

      /**
       * [提交表单]
       * @return {[type]} [description]
       */
      onSubmit() {
        this.imgUrlList = [];
        this.imageList.forEach((item) => {
          // if(item.serverImage.match(API_ROOT)) {
          //   this.imgUrlList.push(item.serverImage);
          // } else {
          //   this.imgUrlList.push(API_ROOT + item.serverImage);
          // }
          this.imgUrlList.push(item.serverImage);
        });

        //        this.updatePubNumberInfo();
        this.$refs.pubNumberForm.validate((valid) => {
          if (valid) {
            this.updatePubNumberInfo();
          }
        });
      },
    },
    async created() {
      this.queryPubNumbers();
      this.pubNumberInfo = Object.assign({}, this.proppubNumberInfo);
      if (this.user.role.indexOf("brands") > -1) {
        this.pubNumberInfo.customer = this.user.customerId;
        this.isBrand = true;
      }
      this.queryCustomers(""); //初始化数据
      if (this.isEdit) {
        if (this.pubNumberInfo.imageUrls) {
          let temp = this.pubNumberInfo.imageUrls.split(",");
          temp.forEach((item) => {
            this.imageList.push({ fileImage: item, serverImage: item });
          });
        }
      }
      //console.log(this.pubNumberInfo)

      //提前初始化选项，让其选项在focus的时候就获取到
    },
    watch: {
      customerDetailVisible(newVal, oldVal) {
        if (newVal === false) {
          this.isEdit = false;
          this.imageList = [];
          this.pubNumberInfo = {
            id: "",
            name: "",
            linkUrl: "",
            customer: "",
            connectName: "",
            connectTel: "",
            appId: "",
            originId: "",
            appSecret: "",
          };
          this.$refs.pubNumberForm.resetFields();
        } else {
          if (!this.isEdit) {
            this.queryCustomers();
          }
          if (this.isBrand) {
            this.pubNumberInfo.customer = this.user.customerId;
          }
        }
      },
      proppubNumberInfo(newVal) {
        if (newVal && this.isEdit) {
          this.pubNumberInfo = Object.assign({}, newVal);
          if (this.pubNumberInfo.imageUrls) {
            let temp = this.pubNumberInfo.imageUrls.split(",");
            temp.forEach((item) => {
              this.imageList.push({ fileImage: item, serverImage: item });
            });
          }
        }
      },
    },
  };
</script>
